import * as React from "react"

const html = `<Seo title="404: Not found" />
    <div>
      <h1>404: Page Not Found</h1>
      <hr />
      <p>The page you are looking for does not exist.</p>
    </div>`

const NotFoundPage = () => {
  return (
    <main>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </main>
  )
}

export default NotFoundPage
